import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, Modal, Button } from '@mui/material';
import Stories from 'react-insta-stories';
import { useTranslation } from 'react-i18next';
import RPFirstImage from '../../../Assets/Pngs/story/rp-1-crop.png';
import RPFirstSlide from '../../../Assets/Pngs/story/rp-1.png';
import BalanceReferralSvg from './../../../Assets/Svg/balance_referral';
import MyReferralsSvg from './../../../Assets/Svg/my_referrals_svg_story';

const FirstSlide = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                width: "100%",
                paddingTop: "100px",
                paddingLeft: "10px",
                height: '100%',
                background: "linear-gradient(180deg, rgba(158,247,192,1) 0%, rgba(1,145,69,1) 100%)",
            }}
        >
            <Box>
                <Typography variant="h4" sx={{ color: '#fff', fontWeight: 700 }}>
                    {t('webappz_balance_referral')}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: '#fff', marginTop: "15px" }}
                >
                    {t('earn_with_webappz')}
                </Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Avatar
                    src={RPFirstSlide}
                    alt={t('referral_program')}
                    sx={{ width: 220, height: 220, borderRadius: 0 }}
                />
            </Box>
            <Button
                sx={{
                    position: "absolute",
                    bottom: "30px",
                    width: "calc(100% - 40px)",
                    background: "#fff",
                    borderRadius: "14px",
                    padding: "12px 14px",
                    left: "20px",
                    fontWeight: 700,
                    cursor: "pointer",
                    zIndex: 10000
                }}
                onClick={() => {
                    window.Telegram.WebApp.openLink(t('referral_program_link'), {try_instant_view: true});
                }}
            >
                {t('more_details')}
            </Button>
        </Box>
    );
}

const SecondSlide = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                width: "100%",
                paddingTop: "100px",
                paddingLeft: "10px",
                height: '100%',
                background: "linear-gradient(180deg, rgba(158,247,192,1) 0%, rgba(1,145,69,1) 100%)",
            }}
        >
            <Box>
                <Typography variant="h4" sx={{ color: '#fff', fontWeight: 700 }}>
                    {t('program_levels')}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: '#fff', marginTop: "15px" }}
                >
                    <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
                        <li>
                            <p style={{ color: '#fff' }}>{t('level_one')}</p>
                        </li>
                        <li>
                            <p style={{ color: '#fff' }}>{t('level_two')}</p>
                        </li>
                    </ul>
                    <p style={{ color: "#fff" }}>{t('view_referrals')}</p>
                </Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <MyReferralsSvg />
            </Box>
            <Button
                sx={{
                    position: "absolute",
                    bottom: "30px",
                    width: "calc(100% - 40px)",
                    background: "#fff",
                    borderRadius: "14px",
                    padding: "12px 14px",
                    left: "20px",
                    fontWeight: 700,
                    cursor: "pointer",
                    zIndex: 10000
                }}
                onClick={() => {
                    window.Telegram.WebApp.openLink(t('referral_program_link'), {try_instant_view: true});
                }}
            >
                {t('more_details')}
            </Button>
        </Box>
    );
}

const ThirdSlide = () => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                width: "100%",
                paddingTop: "100px",
                paddingLeft: "10px",
                height: '100%',
                background: "linear-gradient(180deg, rgba(158,247,192,1) 0%, rgba(1,145,69,1) 100%)",
            }}
        >
            <Box>
                <Typography variant="h4" sx={{ color: '#fff', fontWeight: 700 }}>
                    {t('balance_and_withdrawals')}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: '#fff', marginTop: "15px" }}
                >
                    <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
                        <li>
                            <p style={{ color: '#fff' }}>{t('balance_in_ton')}</p>
                        </li>
                        <li>
                            <p style={{ color: '#fff' }}>{t('withdraw_from_1_ton')}</p>
                        </li>
                        <li>
                            <p style={{ color: '#fff' }}>{t('bonuses_instant')}</p>
                        </li>
                    </ul>
                </Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div className={"smooth-fade-container"}>
                    <BalanceReferralSvg className="smooth-fade-image" />
                </div>
            </Box>
            <Button
                sx={{
                    position: "absolute",
                    bottom: "30px",
                    width: "calc(100% - 40px)",
                    background: "#fff",
                    borderRadius: "14px",
                    padding: "12px 14px",
                    left: "20px",
                    fontWeight: 700,
                    cursor: "pointer",
                    zIndex: 10000
                }}
                onClick={() => {
                    window.Telegram.WebApp.openLink(t('referral_program_link'), {try_instant_view: true});
                }}
            >
                {t('more_details')}
            </Button>
        </Box>
    );
}

const storyContent = [
    {
        content: () => <FirstSlide />,
    },
    {
        content: () => <SecondSlide />,
    },
    {
        content: () => <ThirdSlide />,
    },
];

const StoriesBlock = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            window.Telegram.WebApp.setHeaderColor("#9ef7c0");
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
        } else {
            window.Telegram.WebApp.setHeaderColor(window.Telegram.WebApp.themeParams.bg_color);
            window.Telegram.WebApp.MainButton.show();
            window.Telegram.WebApp.SecondaryButton.show();
        }
    }, [open]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAllStoriesEnd = () => {
        handleClose();
    };

    return (
        <>
            <Box
                onClick={handleOpen}
                sx={{
                    width: "min-content",
                    padding: "3px",
                    borderRadius: "16px",
                    textAlign: 'center',
                    cursor: 'pointer',
                    transition: 'transform 0.2s',
                    border: '2px solid #1976d2',
                }}
            >
                <Box
                    sx={{
                        background: "linear-gradient(180deg, rgba(158,247,192,1) 0%, rgba(1,145,69,1) 100%)",
                        borderRadius: "14px"
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            position: "absolute",
                            display: 'block',
                            fontSize: "10px",
                            color: "#fff",
                            marginLeft: "3px",
                            marginTop: "30px",
                            fontWeight: 500,
                            textAlign: 'left',
                        }}
                        dangerouslySetInnerHTML={{__html: t('referral_program_html')}}
                    >
                    </Typography>
                    <Avatar
                        src={RPFirstImage}
                        alt={t('referral_program')}
                        sx={{
                            width: 80,
                            height: 80,
                            margin: '0 auto',
                            borderRadius: "14px",
                        }}
                    />
                </Box>
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                fullScreen
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    zIndex: 1300,
                    position: "absolute",
                    top: 0
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 480,
                        height: '100%',
                        maxHeight: 800,
                        backgroundColor: '#000',
                        position: 'relative',
                    }}
                >
                    <Stories
                        stories={storyContent}
                        defaultInterval={5000}
                        width="100%"
                        height="100%"
                        onAllStoriesEnd={handleAllStoriesEnd}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default StoriesBlock;
