import React, { useEffect, useState } from 'react';
import WebAppzAPI from './API/WebAppzAPI';
import ShopCard from './Components/Cards/ShopCard';
import LoadingBlock from './Components/Blocks/LoadingBlock';

import InvoicePage from './Invoice/InvoicePage';

import Settings from './Comps/Control/Settings';

import WelcomePage from './Pages/WelcomePage';

import { useTranslation } from 'react-i18next';
import './i18n';

import './Assets/Css/style.css';

import {TonConnectUIProvider} from '@tonconnect/ui-react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Alert } from '@mui/material';
import DisplayWallet from "./Components/TelegramWallet/WalletInfo";

const App = () => {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [shops, setShops] = useState(null);
    const [refs, setRefs] = useState(null);
    const [isFetched, setIsFetched] = useState(false);
    const [page, setPage] = useState('welcome');
    const [openChannelDialog, setOpenChannelDialog] = useState(false);
    const [openShopNameDialog, setOpenShopNameDialog] = useState(false);
    const [channelLink, setChannelLink] = useState('');
    const [shopName, setShopName] = useState('');
    const [error, setError] = useState('');
    const [openCreateShopDialog, setOpenCreateShopDialog] = useState(false);
    const [loadingCreateShop, setLoadingCreateShop] = useState(false);

    const authenticateBiometric = async () => {
        try {

            const initBiometricManager = () => {
                return new Promise((resolve, reject) => {
                    window.Telegram.WebApp.BiometricManager.init(() => {
                        if (window.Telegram.WebApp.BiometricManager.isInited) {
                            resolve();
                        } else {
                            reject(false);  // Возвращаем false, если не удалось инициализировать
                        }
                    });
                });
            };

            // Превращаем запрос доступа в промис
            const requestBiometricAccess = () => {
                return new Promise((resolve, reject) => {
                    window.Telegram.WebApp.BiometricManager.requestAccess({}, (isAccessGranted) => {
                        if (isAccessGranted) {
                            resolve();
                        } else {
                            reject(false);  // Возвращаем false, если доступ отклонен
                        }
                    });
                });
            };

            // Превращаем аутентификацию в промис
            const authenticateUser = () => {
                return new Promise((resolve, reject) => {
                    window.Telegram.WebApp.BiometricManager.authenticate({}, (isAuthenticated) => {
                        if (isAuthenticated) {
                            resolve(true);  // Возвращаем true, если аутентификация успешна
                        } else {
                            reject(false);  // Возвращаем false, если аутентификация не удалась
                        }
                    });
                });
            };

            await initBiometricManager();
            await requestBiometricAccess();
            const result = await authenticateUser();

            return result;

        } catch (error) {
            console.error("Ошибка биометрической аутентификации:", error);
            return false;  // Возвращаем false, если произошла ошибка на любом этапе
        }
    }

    const [openModalShops, setOpenModalShops] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isFetched) {
            const fetchData = async () => {
                const WebApp = window.Telegram.WebApp;

                if (typeof (WebApp.initDataUnsafe.user) !== 'undefined') {
                    let userData = {
                        userid: `${WebApp.initDataUnsafe.user.id}`,
                        first_name: `${WebApp.initDataUnsafe.user.first_name}`,
                        last_name: `${WebApp.initDataUnsafe.user.last_name}`,
                        username: `${WebApp.initDataUnsafe.user.username === undefined ? null : WebApp.initDataUnsafe.user.username}`,
                        refID: '0',
                    };

                    if(window.Telegram.WebApp.initDataUnsafe.start_param !== undefined) {
                        const params = window.Telegram.WebApp.initDataUnsafe.start_param.split('|');
                        params.some(param => {
                            const match = param.match(/ref(\d+)/);
                            if (match) {
                                userData.refID = match[1];
                                return true;
                            }
                            return false;
                        });

                        params.some(param => {
                            const match = param.match(/invoice(\w+)/);
                            if (match) {
                                userData.invoice = match[1];
                                return true;
                            }
                            return false;
                        });

                        params.some(param => {
                            const match = param.match(/shop(\d+)/);
                            if (match) {
                                userData.shopIDfind = match[1];
                                setOpenModalShops(true);
                                return true;
                            }
                            return false;
                        });

                        params.some(param => {
                            const match = param.match(/connectwallet/);
                            if (match) {
                                userData.connectwallet = true;
                                return true;
                            }
                            return false;
                        });
                    }

                    window.Telegram.WebApp.expand();

                    var connectData = await WebAppzAPI.OAuthConnect({ userData });
                    userData.userPhoto = connectData.userPhoto;
                    userData.balance = connectData.balance;
                    userData.token = connectData.token;
                    userData.generate_images_history = connectData.generate_images_history;
                    userData.firstLevelRefs = connectData.firstLevelRefs;
                    userData.secondLevelRefs = connectData.secondLevelRefs;
                    userData.BiometricConfirm = connectData.BiometricConfirm;
                    userData.WalletAddress = connectData.WalletAddress;
                    if(connectData.oo !== undefined) {
                        userData.oo = connectData.oo;
                    }

                    if (userData.BiometricConfirm === 1) {
                        const authBiometricAnswere = await authenticateBiometric();
                        userData.authBiometricAnswere = authBiometricAnswere;
                    }
                    setUser(userData);
                    if(connectData.shop_answer !== null && connectData.shop_answer !== undefined) {
                        userData.shop_answer = connectData.shop_answer;
                    }
                    setRefs({
                        'firstLevel': connectData.firstLevelRefs,
                        'secondLevel': connectData.secondLevelRefs,
                    });
                    setShops(connectData.shops);

                    if(connectData.language_code === null || connectData.language_code === undefined) {
                        i18n.changeLanguage(window.Telegram.WebApp.initDataUnsafe.user.language_code);
                        WebAppzAPI.changeLanguage(window.Telegram.WebApp.initDataUnsafe.user.language_code, userData);
                        userData.language_code = window.Telegram.WebApp.initDataUnsafe.user.language_code;
                    } else {
                        i18n.changeLanguage(connectData.language_code);
                        userData.language_code = connectData.language_code;
                    }

                    // window.Telegram.WebApp.MainButton.text = t('invite_button');

                } else {
                    window.location.href = '/block';
                }
            };

            fetchData();
            setIsFetched(true);
        }
    }, [isFetched, t, i18n]);

    React.useEffect(() => {
        if(user !== null) {
            if(page === 'welcome') {

            } else if(page === 'webappz_chat') {
                window.Telegram.WebApp.BackButton.show();
                window.Telegram.WebApp.BackButton.onClick(() => {
                    setPage('welcome');
                });
            }

            return () => {
                window.Telegram.WebApp.MainButton.offClick();
                window.Telegram.WebApp.BackButton.offClick();
            };
        }
    }, [page, user]);

    const sendDataToServer = async (data, user) => {
        data.userid = user.userid;
        data.token = user.token;
        await WebAppzAPI.createShopRequest(data);
    };

    React.useEffect(() => {
        if(user !== null && (user.authBiometricAnswere === undefined || user.authBiometricAnswere === true) && user.invoice === undefined && user.connectwallet === undefined) {
            window.Telegram.WebApp.SecondaryButton.text = t('invite_button');
            window.Telegram.WebApp.SecondaryButton.color = '#0098EA';
            window.Telegram.WebApp.SecondaryButton.textColor = '#fff';

            window.Telegram.WebApp.SecondaryButton.onClick(() => {
                window.location.href="https://t.me/share/url?url=https://t.me/webappzconnectbot/app?startapp=ref" + user.userid + "&text=" + t('connect_link');
            });

            if(user.shop_answer === null) {
                window.Telegram.WebApp.SecondaryButton.show();
                window.Telegram.WebApp.MainButton.show();
            }
        } else if(user && user.invoice === undefined) {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
        } else {
            window.Telegram.WebApp.SecondaryButton.hide();
        }
    }, [t, user]);

    const [openSettings, setOpenSettings] = useState(false);

    window.Telegram.WebApp.SettingsButton.show();
    window.Telegram.WebApp.SettingsButton.onClick(() => {
        if(openSettings) {
            setOpenSettings(false);
        } else {
            setOpenSettings(true);
        }
    });

    const handleChannelSubmit = async () => {
        window.Telegram.WebApp.MainButton.showProgress();
        setLoadingCreateShop(true);
        if (channelLink.length < 1 || (!channelLink.startsWith('https://') && !channelLink.startsWith('@'))) {
            setError(t('incorrect_channel_link'));
            setLoadingCreateShop(false);
            return;
        }

        const data = { text: channelLink, isChannel: true };
        await sendDataToServerCreateShop(data, user);
        setOpenChannelDialog(false);
        setLoadingCreateShop(false);
        setChannelLink('');
        window.Telegram.WebApp.MainButton.hideProgress();
    };

    const handleShopSubmit = async () => {
        setLoadingCreateShop(true);
        window.Telegram.WebApp.MainButton.showProgress();
        if (shopName.length < 4 || shopName.length > 24) {
            setError(t('max_24_symbols'));
            setLoadingCreateShop(false);
            return;
        }

        const data = { text: shopName, isChannel: false };
        await sendDataToServerCreateShop(data, user);
        setOpenShopNameDialog(false);
        setShopName('');
        setLoadingCreateShop(false);
        window.Telegram.WebApp.MainButton.hideProgress();
    };

    const sendDataToServerCreateShop = async (data, user) => {
        data.userid = user.userid;
        data.token = user.token;
        const response = await WebAppzAPI.createShopRequest(data);
        if(response.status === 'success') {
            window.Telegram.WebApp.showAlert(t('shop_successfully_created'));
            window.location.href = 'https://t.me/webappzconnectbot';
            window.Telegram.WebApp.close();
        } else if(response.status === 'error') {
            window.Telegram.WebApp.showAlert(response.message);
        } else {
            window.Telegram.WebApp.showAlert("Server error");
        }
    };

    React.useEffect(() => {
        if(user !== null && user.invoice === undefined) {
            if(openSettings) {
                window.Telegram.WebApp.MainButton.hide();
                window.Telegram.WebApp.SecondaryButton.hide();
                // return <Settings openSettings={openSettings} setUser={setUser} setOpenSettings={setOpenSettings} user={user} shops={shops} refs={refs} />
            } else {
                window.Telegram.WebApp.MainButton.show();
                window.Telegram.WebApp.SecondaryButton.show();
            }
        } else {
            window.Telegram.WebApp.MainButton.hide();
            window.Telegram.WebApp.SecondaryButton.hide();
        }
    }, [openSettings, user]);

    React.useEffect(() => {
        if(user !== null && user.invoice === undefined) {
            const mainButton = window.Telegram.WebApp.MainButton;
            const secondaryButton = window.Telegram.WebApp.SecondaryButton;

            const handleMainButtonClick = () => {
                if(openChannelDialog && channelLink.length < 1) {
                    setOpenChannelDialog(false);
                    setOpenShopNameDialog(true);
                } else if(openChannelDialog && channelLink.length > 0) {
                    handleChannelSubmit();
                } else if(openShopNameDialog && shopName.length < 1) {
                    setOpenChannelDialog(true);
                    setOpenShopNameDialog(false);
                } else if(openShopNameDialog && shopName.length > 0) {
                    handleShopSubmit();
                } else {
                    setOpenChannelDialog(true);
                }
            }

            if(openChannelDialog && channelLink.length < 1) {
                mainButton.onClick(handleMainButtonClick);
                mainButton.text = t('i_dont_have_channel');
                secondaryButton.hide();
            } else if(openChannelDialog && channelLink.length > 0) {
                mainButton.onClick(handleMainButtonClick);
                mainButton.text = t('create_shop');
                secondaryButton.hide();
            } else if(openShopNameDialog && shopName.length < 1) {
                mainButton.onClick(handleMainButtonClick);
                mainButton.text = t('back');
                secondaryButton.hide();
            } else if(openShopNameDialog && shopName.length > 0) {
                mainButton.onClick(handleMainButtonClick);
                mainButton.text = t('create_shop');
                secondaryButton.hide();
            } else {
                mainButton.onClick(handleMainButtonClick);
                setOpenChannelDialog(false);
                setOpenShopNameDialog(false);
                mainButton.text = t('create_shop');
                secondaryButton.show();
                mainButton.color = '#0098EA';
            }

            return () => {
                mainButton.offClick(handleMainButtonClick)
            }
        }

    }, [openCreateShopDialog,openShopNameDialog, t, shopName, openChannelDialog, channelLink, user]);

    if(user !== null && user.authBiometricAnswere !== undefined && user.authBiometricAnswere === false) {
        window.Telegram.WebApp.MainButton.hide();
        window.Telegram.WebApp.SecondaryButton.hide();
        return <LoadingBlock message={"Failed to verify biometry"} />;
    }

    return (
        <>
            <Dialog open={openCreateShopDialog} onClose={() => setOpenCreateShopDialog(false)}>
                <DialogTitle sx={{ background: "var(--tg-theme-bg-color)" }}>{t('do_you_have_a_telegram_channel')}</DialogTitle>
                <DialogContent sx={{ background: "var(--tg-theme-bg-color)" }}>
                    <Button onClick={() => { setOpenCreateShopDialog(false); setOpenChannelDialog(true); }}>{t('yes')}</Button>
                    <Button onClick={() => { setOpenCreateShopDialog(false); setOpenShopNameDialog(true); }}>{t('no')}</Button>
                </DialogContent>
            </Dialog>

            <Dialog open={openChannelDialog} fullScreen={true} onClose={() => setOpenChannelDialog(false)}>
                <DialogTitle sx={{ background: "var(--tg-theme-bg-color)" }}>{t('enter_channel_link')}</DialogTitle>
                <DialogContent sx={{ background: "var(--tg-theme-bg-color)" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="channelLink"
                        label={t('linkusername_to_channel')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={channelLink}
                        onChange={(e) => setChannelLink(e.target.value)}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    {
                        !loadingCreateShop && (
                            <DialogActions sx={{ background: "var(--tg-theme-bg-color)" }}>
                                <Button onClick={() => setOpenChannelDialog(false)}>{t('cancel')}</Button>
                                {/*<Button onClick={handleChannelSubmit}>{t('further')}</Button>*/}
                            </DialogActions>
                        )
                    }
                </DialogContent>
            </Dialog>

            <Dialog open={openShopNameDialog} fullScreen={true} onClose={() => setOpenShopNameDialog(false)}>
                <DialogTitle sx={{ background: "var(--tg-theme-bg-color)" }}>{t('notify_max_24_symbols')}</DialogTitle>
                <DialogContent sx={{ background: "var(--tg-theme-bg-color)" }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="shopName"
                        label={t('enter_shop_name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={shopName}
                        onChange={(e) => setShopName(e.target.value)}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    {
                        !loadingCreateShop && (
                            <DialogActions sx={{ background: "var(--tg-theme-bg-color)" }}>
                                <Button onClick={() => setOpenShopNameDialog(false)}>{t('cancel')}</Button>
                                {/*<Button onClick={handleShopSubmit}>{t('further')}</Button>*/}
                            </DialogActions>
                        )
                    }
                </DialogContent>
            </Dialog>

            {user && user.invoice === undefined && user.connectwallet === undefined ? (
                <>
                    {
                        openSettings && (
                            <Settings openSettings={openSettings} setUser={setUser} setOpenSettings={setOpenSettings} user={user} shops={shops} refs={refs} />
                        )
                    }
                    {
                        page === 'welcome' && !openSettings ? (
                            <div className="App">
                                <WelcomePage user={user} openModalShops={openModalShops} setOpenModalShops={setOpenModalShops} refs={refs} shops={shops} />
                                {
                                    user.shop_answer !== undefined && (
                                        <ShopCard userData={user} isOpen={true} openModal={openModalShops} openShop={true} setOpenModal={setOpenModalShops} shop={user.shop_answer} setOpenShop={() => {}} />
                                    )
                                }
                            </div>
                        ) : null
                    }
                </>
            ) : user && user.invoice !== undefined && user.connectwallet === undefined ? (
                <TonConnectUIProvider
                    manifestUrl={"https://a0yqw43aon9fqz7oj618yt3vfkalv0.aydawcux16rbjxf5ufl5c6y.ru/tonconnect-manifest.json"}
                >
                    <InvoicePage userData={user} />
                </TonConnectUIProvider>
            ) : user && user.connectwallet !== undefined && user.invoice === undefined && (
                <div style={{width: "100%"}}>
                    <DisplayWallet user={user} />
                </div>
            ) }
        </>
    );
}

export default App;